import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import * as styles from './Checkbox.module.scss'

const Checkbox = forwardRef(
  ({ label, value, defaultChecked, checked, disabled, name, id, onChange }, ref) => {
    return (
      <div className={styles.Checkbox}>
        <input
          className={styles.CheckboxInput}
          id={id}
          ref={ref}
          value={value}
          name={name}
          defaultChecked={defaultChecked}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          type="checkbox"
          data-qa="checkbox"
        />
        <label className={styles.CheckboxLabel} htmlFor={id} data-qa="checkbox-label">
          {label}
        </label>
      </div>
    )
  }
)

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export { Checkbox }
export default Checkbox
