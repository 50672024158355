/* eslint-disable camelcase */

import { Fragment, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from 'components/button'
import { Layout } from '../../layouts/layout'
import { Container, Row, Col } from '../../grid'
import { Checkbox } from '../../checkbox'
import * as styles from './Marstonemodul.module.scss'

const MarstoneModule = () => {
  const [t] = useTranslation()
  const [solicitorCheck, setSolicitorCheck] = useState(false)
  const [riskCheck, setRickCheck] = useState(false)

  return (
    <Container className={styles.Content}>
      <Row className={styles.checkBoxRow}>
        <div className={styles.checkBoxCont}>
          <span className={styles.marstoneDisc}>
            I have received and acknowledge the Solicitor's Disclosure Statement (gray box below)
            explaining the compensation for referrals.*
          </span>
          <Checkbox
            onChange={() => setSolicitorCheck(!solicitorCheck)}
            label="I have read the Solicitor's Disclosure Statement."
            id="solicitorCheck"
            value="solicitorCheck"
            checked={solicitorCheck}
          />
        </div>
        <div className={styles.checkBoxCont}>
          <span className={styles.marstoneDisc}>
            I acknowledge that the products and services being offered or recommended are (a) not
            insured by deposit insurance; (b) are not deposits with, obligations of, or guaranteed
            by Elevations Credit Union; and (c) are subject to investment risk, including the
            potential loss of principal.*
          </span>
          <Checkbox
            onChange={() => setRickCheck(!riskCheck)}
            label="I have read the Risk Statement above."
            id="riskCheck"
            value="riskCheck"
            checked={riskCheck}
          />
        </div>
        <div className={styles.disclaimer}>
          <p>*Required</p>
        </div>
        <div className={styles.buttonCont}>
          <Button
            disabled={!riskCheck || !solicitorCheck}
            alt="Independent Investing"
            url="https://elevations.wealth.marstone.com"
            className={!riskCheck || !solicitorCheck ? styles.disabledButton : null}
          >
            Get Started
          </Button>
        </div>
      </Row>
    </Container>
  )
}

export { MarstoneModule }
export default MarstoneModule
